import React, { Component } from "react"



/* Component Messages */
class Messages extends Component {

    render = () => {

        const { messages } = this.props

        return (
            <div className="messages">

                <div className="messages-title">Чат</div>

                <div className="message-box">
                    {messages.map((message, index) => (
                        <div className="message" key={`${index}`} style={{ opacity: ((index > 5) ? 0.5 : 1 - index * 0.1) }}>
                            <div className="message-name">{message.name}</div>
                            <div className="message-text">{message.message}</div>
                        </div>
                    ))}
                </div>

            </div>
        )
    }

}

export default Messages