/* Audio */

export const audio = (sound = null, volume = 1) => {

    if (!sound) {
        return
    }

    const list = {
        'choise': '/sounds/choise.mp3',
        'action': '/sounds/action.mp3',
        'message': '/sounds/message.mp3',
    }

    if (list[sound]) {

        const soundEffect = new Audio()
        soundEffect.src = list[sound]
        soundEffect.volume = volume

        try {
            soundEffect.play()
        }
        catch (e) {
            soundEffect.remove()
            console.log(e)
        }

        soundEffect.remove()

    }

    return

}