import React, { Component } from "react"


/* Component Dealer */
class Dealer extends Component {

    render = () => {

        const { dealer, logout } = this.props

        return (
            <div className="dealer">

                <img src="/images/croupier.png" alt="Croupier" />

                <div className="dealer-text">
                    Дилер <span>{dealer.name}</span>
                </div>

                <img src="/images/more.png" alt="MORE" className="more" />

                <div className="dealer-dropdown" onClick={() => logout()}>
                    <img src="/images/power.png" alt="Power" />
                    <p>Выйти</p>
                </div>

            </div>
        )
    }

}

export default Dealer