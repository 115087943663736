import React, { Component } from "react"




/* Component Pause */
class Pause extends Component {

    render = () => {

        const { pause, command } = this.props

        if (command !== "waiting" && command !== "noplayer") {
            return (
                <div onClick={() => pause()} className="pause" style={{ opacity: command === "deal" ? 1 : 0.5 }}>

                    <img src="/images/pause.png" alt="Pause" />

                    <div className="pause-inner">
                        <div className="pause-inner-title">Приостановить игру</div>
                        <div className="pause-inner-text">и вызвать в службу поддержки</div>
                    </div>

                </div>
            )
        }

        return <div />
    }

}

export default Pause