import React, { Component } from "react"

/* Modal */
import Modal from 'react-modal'


/* Class Pause Modal */
class PauseModal extends Component {
    render = () => {

        const { isPaused } = this.props

        return (
            <Modal
                isOpen={isPaused}
                className="pause-modal"
                overlayClassName="pause-overlay"
                closeTimeoutMS={200}
            >
                <div className="pause-modal-title">Игра приостановлена</div>
                <div className="pause-modal-text">Служба поддержки решает эту ситуацию. Пожалуйста, подождите.</div>
            </Modal>
        )
    }
}

export default PauseModal