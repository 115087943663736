import React, { Component } from "react"

/* Modal */
import Modal from 'react-modal'


/* Class Revoke Modal */
class RevokeModal extends Component {
    render = () => {

        const { isRevoke } = this.props

        return (
            <Modal
                isOpen={isRevoke}
                className="revoke-modal"
                overlayClassName="revoke-overlay"
                closeTimeoutMS={200}
            >
                <div className="revoke-modal-title">Карты сброшены.</div>
                <div className="revoke-modal-text">Проведите все карты через сканер еще раз...</div>
            </Modal>
        )
    }
}

export default RevokeModal
