const point = "https://ca.rekopgames.com"
const mediapoint = "https://qmpb.rekopgames.com"

/* SOCKET TOKEN */
const DEALER_MIDDLEWARE_TOKEN = "3b64f764-488f-4c6f-9a79-6fbde67146fd"

/* DEALER MONITOR TOKEN */
const DEALER_MONITOR_TOKEN = "1d3f299f-85f2-4110-a2a0-a34ef795a88a"

/* POINTS */
const startpoint = `${point}/api/`


export { point, mediapoint, startpoint, DEALER_MONITOR_TOKEN, DEALER_MIDDLEWARE_TOKEN }
