import React, { Component } from "react"

/* Constants */
import { sound } from '../constants'



/* Component Timing */
class Timing extends Component {

    constructor() {
        super()

        this.state = {
            second: 0,
            command: ''
        }

        this._timing = null
    }


    start = (counter, command = '') => {

        this.setState({ second: counter, command }, () => {

            if (this._timing) {
                clearInterval(this._timing)
                this._timing = null
            }

            this._timing = setInterval(() => {

                const { second } = this.state
                const { isPaused } = this.props

                if (second === 1) {

                    if (command === 'additional' || command === 'dealer') {
                        sound.audio('action')
                    }

                    if (command === 'deal') {
                        sound.audio('choise')
                    }

                }

                if (second <= 0) {
                    clearInterval(this._timing)
                    this._timing = null
                }
                else {
                    if (!isPaused) {
                        this.setState({ second: second - 1 })
                    }
                }

            }, 1000)

        })

    }



    /* Draw Command */
    _command = () => {

        const { command } = this.state

        if (command === "deal") {
            return <div className="timing-text">Новая игра!<br />Ожидание игрока ...</div>
        }

        if (command === "additional") {
            return <div className="timing-text">Откройте дополнительные карты<br />через:</div>
        }

        if (command === "dealer") {
            return <div className="timing-text">Откройте карты дилера<br />через:</div>
        }

        if (command === "purchase") {
            return <div className="timing-text">Покупка игры для дилера<br />через:</div>
        }

        if (command === "collect") {
            return <div className="timing-text">Соберите карты<br />через:</div>
        }

        if (command === "end") {
            return <div className="timing-text">Игра завершена!<br />Подготовьте карты.</div>
        }

        if (command === "noplayer") {
            return <div className="timing-text">Нет игроков</div>
        }

    }



    render = () => {

        const { second, command } = this.state

        let zero = ''
 
        if (second === 0) {
            zero = 'timing-zero'
        }

        if (command === "dealer" && parseInt(second) > 10) {
            zero = 'timing-zero'
        }

        if (command === "additional" && parseInt(second) > 10) {
            zero = 'timing-zero'
        }

        if (command === "collect" && parseInt(second) > 5) {
            zero = 'timing-zero'
        }

        if (command === "noplayer") {
            return (
                <div className="timing">
                    {this._command()}
                </div>
            )
        }

        return (
            <div className={`timing ${zero}`}>
                {this._command()}
                <div className="timing-box">{second}</div>
                <div className="timing-shadow" />
            </div>
        )
    }

}

export default Timing